import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "./../components/Layout";
import { PageWrapperDiv } from "./../components/PressStyles/elements";
import { HeaderImageWrapper } from "../styles/404";

const NotFoundPage = () => {
  const notFoundPageData = useStaticQuery(graphql`
    {
      craftNotFoundPageNotFoundPageEntry {
        richTextField
        title
        headerImage {
          ... on Craft_editorialAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440, height: 610, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `).craftNotFoundPageNotFoundPageEntry;

  const { title, richTextField, headerImage } = notFoundPageData;

  return (
    <Layout
      backgroundColor={"var(--white)"}
      footerColor={"var(--green)"}
      skipHeader
      title={title}
      SEODescription={richTextField}
    >
      <PageWrapperDiv>
        <HeaderImageWrapper>
          <Img fixed={headerImage[0].wideImage.childImageSharp.fixed} />
          <div>
            <h1>404</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: `<h2>${title}</h2><div>${richTextField}</div>`,
              }}
            />
          </div>
        </HeaderImageWrapper>
      </PageWrapperDiv>
    </Layout>
  );
};

export default NotFoundPage;
